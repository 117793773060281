import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"

export const Head = ({ data }) => (
    <title>404 Not Found {data.site.siteMetadata.title}</title>
)

const Error404 = ({ data }) => {
    return (
        <Layout>
            <article>
                <h1 className="title is-1">Page Not Found 😢</h1>
                <p>This page does not exists</p>
            </article>
        </Layout>
    )
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`

export default Error404
